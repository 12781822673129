import React, { useEffect, useState } from "react";
import { graphql, StaticQueryDocument } from "gatsby";
import "../styles/general.styles.scss";
import { useTranslation } from "gatsby-plugin-react-i18next";
import LayoutComponent from "../components/LayoutComponent/LayoutComponent";
import { CircleConfig } from "../components/ContentRowComponent/ContentRowComponent.types";
import { useWindowSize } from "../utils/useWindowSize";
import ContentRowComponent from "../components/ContentRowComponent/ContentRowComponent";

const ImprintPage: React.FC = () => {
  const { t } = useTranslation();
  const { width } = useWindowSize();

  const [circleConfigs, setCircleConfigs] = useState<CircleConfig[]>([
    {
      color: "#f89a26",
      radius: 2000,
      top: 100,
      weight: 8,
      horiz: -700,
      active: true,
    },
  ]);

  /**
   *
   */
  useEffect(() => {
    switch (true) {
      case width > 769:
        setCircleConfigs([
          {
            color: "#f89a26",
            radius: 2000,
            top: 100,
            weight: 8,
            horiz: -700,
            active: true,
          },
        ]);
        break;

      case width > 600 && width <= 769:
        setCircleConfigs([
          {
            color: "#f89a26",
            radius: 1200,
            top: 100,
            weight: 8,
            horiz: -400,
            active: true,
          },
        ]);
        break;

      case width <= 600:
      default:
        setCircleConfigs([
          {
            active: false,
          },
        ]);
        break;
    }
  }, [width]);

  return (
    <LayoutComponent seo={{ title: t("page.imprint.title") }} sub>
      <ContentRowComponent circleConfig={circleConfigs[0]}>
        <div className="sub-page--content imprint">
          <div className="headline">Impressum</div>
          <div className="text">
            <b>Hauptniederlassung</b>
            <br />
            90.10.® AG Schweiz
            <br />
            Blumenbergstrasse 16
            <br />
            3013 Bern
            <br />
            Schweiz
          </div>
          <div className="text">
            <b>Zweigniederlassung Deutschland</b>
            <br />
            Neumühler Straße 1<br />
            92224 Amberg
            <br />
            Deutschland
          </div>
          <div className="text">
            Vertreten durch den Vorstandsvorsitzenden Oliver Schacke
            <br />
            Telefon: 0049-251-981156-2536 (KEIN SUPPORT)
            <br />
            Support gibt es hier (klick)
            <br />
            E-Mail: de.support@9010.com
            <br />
            USt-IdNr.: DE326657648
            <br />
            eingetragen im Handelsregister des Amtsgerichtes Bern
            <br />
            Handelsregisternummer HRB CH-036.3.045.7838
          </div>
          <div className="text">
            Inhaltlich Verantwortlicher gemäß § 55 RStV:
            <br />
            Herr Oliver Schacke, Blumenbergstrasse 16, 3013 Bern, Schweiz
          </div>
          <div className="text">
            Alternative Streitbeilegung:
            <br />
            Die Europäische Kommission stellt eine Plattform für die
            außergerichtliche Online-Streitbeilegung (OS-Plattform) bereit,
            aufrufbar unter https://ec.europa.eu/odr.
            <br />
            Wir sind seit 22.04.2016 Mitglied der Initiative „FairCommerce“.
            Nähere Informationen unter www.fair-commerce.de.
          </div>
        </div>
      </ContentRowComponent>
    </LayoutComponent>
  );
};

export default ImprintPage;

export const query: StaticQueryDocument = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    medicineParallaxImage: file(relativePath: { eq: "9010-medizin.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    homeParallaxImage: file(relativePath: { eq: "9010-zuhause.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    jobParallaxImage: file(relativePath: { eq: "9010-beruf.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sportParallaxImage: file(relativePath: { eq: "9010-sport.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
